<template>
  <div
    class="accordion service-data"
    id="faqAccordion"
  >
    <div class="accordion-item">
      <h2 
    v-if="hint.titles&& hint.titles[lang]"
      class="accordion-header" :id="'headingOne-'">
        <button :class="['accordion-button', dir_ar]" type="button">
          {{ hint.titles[lang] }}
        </button>
      </h2>
      <div
      v-if="hint.descriptions"
        :id="'collapseOne-'"
        class="accordion-collapse collapse show"
        :aria-labelledby="'headingOne-'"
        data-bs-parent="#faqAccordion"
      >
        <div class="accordion-body" v-html="hint.descriptions[lang]"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["hint"],
};
</script>

<style>
</style>